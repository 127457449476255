import axios from 'axios';
import router from "../router"
import { Network } from "@capacitor/network"
import { Capacitor } from "@capacitor/core"

  const state = {
    ready: false,
    message: null,
    online: navigator.onLine,
    lang: "english",
    loading: false,
    update_interval: null,
    auth: {
      token: null,
      userId: null,
      email: null,
      username: null,
      phone: null,
      /////// location-fix /////////////
      location: [],
      role: null
    },
    roles: {
      0: "employee",
      1: "client",
      2: "manager",
    },

    listItems: [
      
    ]
  };

  const mutations = {};

  const actions = {
    async initApp({dispatch}) {
      try {
        await dispatch("getLocations");
        //////////
        if(!localStorage.getItem("userId")) {
          state.ready = true;
          return dispatch("logout");
        }
        //////////
        if(state.online) {
          const res = await axios.get(`/autoSignin/${localStorage.getItem("userId")}`);
          if(res.data.err) {
            state.ready = true;
            dispatch('writeMessage', res.data.err.message);
            dispatch("logout");
            router.replace("/auth/signin");
            return;
          }
          await dispatch("afterSignin", res.data.auth);
          await dispatch("syncRecords");
        } else {
          dispatch("autoSignin");
        }
        //////////
        await dispatch("getSystemData");
        //////////
        if(localStorage.getItem("lang"))
          state.lang = localStorage.getItem("lang");
        //////////
        state.ready = true;
        ///////////
        // state.update_interval = setInterval(() => {
        //   dispatch("intervalSync");
        // }, 60000);
        /////////////
        if(Capacitor.getPlatform() == 'android') {
          Network.addListener("networkStatusChange", status => {
            state.online = status.connected;
            dispatch("writeMessage", `Your are ${state.online?'online': 'offline'}`);
            if(state.online) dispatch("syncRecords");
          });
        }
        else {
          window.addEventListener("online", () => {
            state.online = navigator.onLine;
            dispatch("writeMessage", "Your are online");
            if(state.online) dispatch("syncRecords");
          });
          window.addEventListener("offline", () => {
            state.online = navigator.onLine;
            console.log(state.online)
            dispatch("writeMessage", "Your are offline");
          });
        }
        ////////////////
        window.addEventListener("beforeunload", (e) => {
          if(state.loading) e.preventDefault();
        });
      }
      catch(err) {
        console.log(err);
      }
    },

    writeMessage(_, mess) {
      state.message = mess;
    },

    closeMessage() {
      state.message = null;
    },

    ///////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////

    async afterSignin({dispatch}, auth) {
      localStorage.setItem("token", auth.token);
      localStorage.setItem("userId", auth.userId);
      localStorage.setItem("email", auth.email);
      localStorage.setItem("username", auth.username);
      localStorage.setItem("phone", auth.phone);
      localStorage.setItem("location", JSON.stringify(auth.location));
      localStorage.setItem("role", auth.role);
      //////////////////////////////////////////
      dispatch("autoSignin");
      /////////////////////////////////////////
      // if(!state.locations.includes(state.auth.location)) state.locations.push({name: state.auth.location})
    },

    toggleLang() {
      if(state.lang == 'english') state.lang = 'arabic';
      else state.lang = 'english';
      localStorage.setItem("lang", state.lang);
    },
    
    /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////

    autoSignin() {
      state.auth = {
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
        username: localStorage.getItem("username"),
        phone: localStorage.getItem("phone"),
        location: JSON.parse(localStorage.getItem("location")),
        role: localStorage.getItem("role")
      }
      axios.defaults.headers.common["authorization"] = state.auth.token;
    },

    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("email");
      localStorage.removeItem("username");
      localStorage.removeItem("phone");
      localStorage.removeItem("location");
      //////
      localStorage.removeItem("devices");
      localStorage.removeItem("records");
      localStorage.removeItem("newRecords");
      state.auth = {
          token: null,
          userId: null,
          email: null,
          username: null,
          phone: null,
          location: [],
          role: null
      }
      state.locationDevices = [];
      state.locationRecords = [];
      state.newRecords = [];

      router.replace("/auth/signin");
    },

    changeLoading(_, status) {
      state.loading = status;
    },

    async intervalSync({dispatch}) {
      if(Capacitor.getPlatform() == 'android') state.online = await Network.getStatus();
      else state.online = navigator.onLine;
      if(state.online) {
        await dispatch("syncRecords");
        // await dispatch("getSystemData");
      }  
    },
    stopInterval() {
      state.update_interval = null;
    }

  };



  const getters = {
    ready() {
      return state.ready; 
    },
    message() {
      return state.message; 
    },
    auth() {
      return state.auth;
    },
    roles() {
      return state.roles;
    },
    online() {
      return state.online;
    },
    loading() {
      return state.loading;
    },
    lang() {
      return state.lang;
    },
    listItems() {
      const token = state.auth.token;
      const role = state.auth.role;
      let listItems = [];
      if(token && role == 2 && state.online) {
        const items = [
          {icon: "camera", link: "/categories", name: "أنواع المعدات"},
          {icon: "camera", link: "/add-category", name: "أضافة نوع جديد"},
          {icon: "camera", link: "/devices", name: "المعدات"},
          {icon: "camera", link: "/add-device", name: "أضافة معدة"},
          {icon: "camera", link: "/locations", name: "المواقع"},
          {icon: "camera", link: "/users", name: "المستخدمين"},
          {icon: "camera", link: "/backup-restore", name: "النسخ الأحتياطي"},
        ];
        listItems = [...listItems, ...items];
      }
      const items = [
        token && {icon: "camera", link: "/", name: "فحص المعدة"},
        state.online && token && (role == 1 || role == 2) && {icon: "camera", link: "/results", name: "النتائج"},
        token && state.auth.username && {icon: "camera", link: `/update-by-user/${state.auth.userId}`, name: state.auth.username},
        state.online && !token && {icon: "camera", link: "/auth/signin", name: "تسجيل الدخول"},
      ]
      listItems = [...listItems, ...items];
      return listItems;
    }
  }


  export default {
    state,
    mutations,
    actions,
    getters
  }