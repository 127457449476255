<template>
  <div class="message" :class="{ open: messageText }">
    <div class="mess-cont" :class="'mess'">
      <div class="text">
        <h3 class="text-center">{{ messageText }}</h3>
      </div>
      <div class="colse">
        <i @click="closeMessage" class="fa fa-times"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messageText: null,
    };
  },
  methods: {
    closeMessage() {
      this.$store.dispatch("closeMessage");
    },
  },
  computed: {
    message() {
      return this.$store.getters.message;
    },
  },
  watch: {
    message(val) {
      this.messageText = val;
      setTimeout(() => this.$store.dispatch("closeMessage"), 5000);
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  z-index: 9000;
  position: fixed;
  top: -100vh;
  left: 0;
  width: 100vw;
  transition: all 0.3s ease-in-out;
  &.open {
    top: 0;
  }
  .mess {
    background: rgb(42, 111, 42);
  }
  .err {
    background: rgb(192, 45, 45);
  }
  .mess-cont {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 12px 4px;
  }
  .text {
    width: 100%;
    h3 {
      font-size: 1.5rem;
      font-weight: 700;
      color: #fff;
      @media (max-width: 700px) {
        font-size: 0.8rem;
      }
    }
  }
  .colse {
    position: absolute;
    top: 20%;
    right: 2%;
    i {
      font-size: 1rem;
      font-weight: 800;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>
