<template>
  <div>
    <div @click="toggleMenu" class="toggle-menu">
      <img src="../assets/imgs/arrow.png" alt="" />
    </div>
    <div class="menu pt-5">
      <div class="row">
        <div class="col-md-12 header">
          <div class="d-flex justify-content-center">
            <i class="fa fa-cogs mx-3"></i>
            <h4>Admin Panel</h4>
          </div>
        </div>
        <div class="col-md-12">
          <div class="items">
            <div
              @click="
                () => {
                  toggleMenu();
                  if (item.link == 'logout') $store.dispatch('logout');
                }
              "
              v-for="(item, i) in items.filter((item) => item)"
              :key="i"
              class="item center"
            >
              <i :class="'fa fa-' + item.icon"></i>
              <router-link v-if="item.link != 'logout'" :to="item.link">{{
                item.name | translate
              }}</router-link>
              <a v-else>{{ item.name | translate }}</a>
            </div>
            <div class="item center">
              <a
                @click="
                  () => {
                    toggleMenu();
                    $store.dispatch('toggleLang');
                  }
                "
              >
                {{ lang == "english" ? "Arabic" : "English" }}
              </a>
              <i class="fa fa-book"></i>
            </div>
            <div
              v-if="$store.getters.auth.token"
              class="item center"
              @click="
                () => {
                  toggleMenu();
                  $store.dispatch('logout');
                }
              "
            >
              <i :class="'fa fa-open'"></i>
              <a>Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toggleMenu() {
      const menu = document.getElementsByClassName("menu");
      menu[0].classList.toggle("open");
    },
  },
  computed: {
    items() {
      return this.$store.getters.listItems;
    },
    lang() {
      return this.$store.getters.lang;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-menu {
  z-index: 9000;
  position: fixed;
  top: 17%;
  right: 0;
  width: 15px;
  height: 35px;
  img {
    width: 100%;
    height: 100%;
  }
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
}
.menu {
  z-index: 8000;
  background: linear-gradient(#000, rgba(39, 38, 38, 0.956));
  position: fixed;
  top: 0;
  height: 100vh;
  transition: 0.3s all ease-in-out;
  overflow: auto;
  // @media (max-width: 700px) {
  right: -200vw;
  &.open {
    right: 0;
  }
  // }
  .header {
    padding: 4px;
    border-bottom: 3px solid rgba(255, 255, 255, 0.673);
    i {
      font-size: 1.7rem;
      color: #fff;
    }
    h4 {
      color: #fff;
      vertical-align: center;
    }
  }
  .items {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    .item {
      width: 100%;
      padding: 9px 3px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.435);
      margin: 6px auto;
      color: #fff;
      a {
        text-decoration: none;
        margin-left: 20px;
        color: #fff;
      }
      i {
        position: absolute;
        right: 3%;
      }
    }
  }
}
</style>
