<template>
  <section>
    <!------------------------------------------------->
    <div v-if="items.length == 0 && !loading" class="row justify-content-center">
      <div class="col-md-12">
        <h3 class="text-center">There is no {{ title | translate }} yet</h3>
      </div>
    </div>
    <div v-if="loading || innerLoading" class="col-md-12">
      <loading />
    </div>
    <!----------------->
    <div v-if="items.length" class="responsive-table">
      <div v-if="title" class="row">
        <div class="col-md-12 center">
          <h3>{{ title | translate }}</h3>
        </div>
      </div>
      <div class="row p-md-2 items">
        <div class="col-md-12">
          <div ref="item" v-for="(item, i) in items" :key="i" class="item">
            <div class="table-row">
              <div v-for="(col, c) in colsNames" :key="c" class="">
                <div class="name _col">
                  <span>{{ colsTitles[c] | translate }}: </span>
                  <h5 v-if="colsTypes[c] == 'readonly'">{{ item[col] | translate }}</h5>
                  <input
                    v-if="colsTypes[c] == 'input'"
                    type="text"
                    :value="item[col]"
                    @change="
                      (e) =>
                        $emit('tableInput', { index: i, key: col, val: e.target.value })
                    "
                  />
                </div>
              </div>
              <div v-if="btns && btns.length > 0" class="">
                <div class="table-btns">
                  <div v-for="(btn, b) in btns" :key="b" class="table-btn">
                    <i @click="btn.method(item, items)" :class="'fa fa-' + btn.icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-footer">
        <div class="d-flex flex-wrap justify-content-center align-items-center">
          <div>
            <span>{{ from }}</span>
            <span>-</span>
            <span>{{ to }}</span>
            <span>of</span>
            <span>{{ totalItems }}</span>
          </div>
          <div>
            <span>
              <i @click="prev" class="fa fa-arrow-left"></i>
            </span>
            <span>
              <i @click="next" class="fa fa-arrow-right"></i>
            </span>
          </div>
          <div>
            <select @change="getItems" v-model.number="limit" class="form-control">
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="1000">all</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  props: [
    "title",
    "api",
    "loading",
    "itemsName",
    "returnedItems",
    "colsTitles",
    "colsNames",
    "colsTypes",
    "btns",
    "color",
    "filter",
  ],
  created() {
    this.getItems();
  },
  updated() {
    const items = this.$refs.item || [];
    if (items.length == 0) return;
    items.forEach((item, i) => {
      if (i % 2 == 0) {
        item.style.background = this.color;
      }
    });
  },
  data() {
    return {
      innerLoading: false,
      items: [],
      stepNumber: 1,
      totalSteps: 0,
      totalItems: 0,
      ////////////////
      limit: 30,
      from: 0,
      to: 0,
    };
  },
  watch: {
    filter() {
      this.getItems();
    },
    stepNumber() {
      this.getItems();
    },
    returnedItems(val) {
      this.items = val;
    },
  },
  methods: {
    next() {
      if (this.stepNumber == this.totalSteps) return;
      this.stepNumber++;
    },
    prev() {
      if (this.stepNumber == 1) return;
      this.stepNumber--;
    },
    getItems() {
      const skip = (this.stepNumber - 1) * this.limit;
      this.innerLoading = true;
      axios.post(this.api + "/" + skip + "/" + this.limit, this.filter).then((res) => {
        this.innerLoading = false;
        if (res.data.err) return this.$store.dispatch("writeMessage", res.data.err);
        if (!res.data[this.itemsName]) this.items = [];
        this.items = res.data[this.itemsName];
        this.from = skip + 1;
        this.to = skip + res.data[this.itemsName].length;
        this.totalItems = res.data.totalItems;
        this.totalSteps = Math.ceil(res.data.totalItems / this.limit);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  height: 400px;
  //   max-height: 500px;
  overflow-y: auto;
  .item {
    text-align: left;
    font-weight: 600;
    padding: 5px;
    margin-top: 13px;
    background: #eee;
    color: rgba(20, 20, 20, 0.776);
    span {
      color: rgba(26, 25, 25, 0.653);
      font-weight: 100;
      margin-right: 6px;
      //   text-decoration: underline;
    }
    h5 {
      font-size: 1rem;
      @media (max-width: 700px) {
        font-size: 0.8rem;
      }
    }
    &:nth-child(odd) {
      background: rgb(131, 7, 79);
      color: #fff;
      span {
        color: rgba(255, 255, 255, 0.694);
      }
      ._col {
        @media (max-width: 700px) {
          &.name {
            border-bottom: 2px solid rgba(241, 239, 239, 0.619);
          }
        }
      }
    }
    ._col {
      width: 100px;
      h5 {
        overflow-x: auto;
      }
      @media (max-width: 700px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &.name {
          margin: 3px 0;
          border-bottom: 2px solid rgba(48, 47, 47, 0.619);
        }
      }
    }
  }
}

.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.table-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .table-btn {
    cursor: pointer;
    padding: 10px;
  }
}

.table-footer {
  background: rgba(223, 221, 221, 0.551);
  padding: 5px;
  span {
    margin: 5px;
    i {
      font-size: 1.4rem;
      cursor: pointer;
    }
  }
}
input {
  width: 40px;
}

::-webkit-scrollbar {
  display: none;
}
</style>
