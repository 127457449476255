const words  = {
    "الأسم": "Name",
    'تعديل بيانات المستخدم': 'Edit user information',
    'تأكد من بياناتك جيدا': "Check you information",
    "تغيير كلمة المرور": "change password",
    "عدم تغيير كلمة المرور": "don't change password",
    "تحديث البيانات": "update information",
    "الآفَة": "Pest",
    "اذا كان لا يوجد حشرات ضع صفر": "if no pest put zero",
    "الحالة": "Status",
    "الحالات": "Status",
    "الأيام": "days",
    "حالات": "Status",
    "كل": "all",
    "الكود": "code",
    "الوصف": "description",
    "الأجراء": "procedure",
    "الأجراءات": "procedures",
    "أضافة نوع جديد": "Add new Category",
    "تأكد من صحة البيانات المدخلة" : "check equipment info",
    "نوع المعدة": "category",
    "الموقع": "location",
    "مواقع": "locations",
    "المواقع": "locations",
    "كود المعدة": "equipment code",
    "المعدة": "equipment",
    "تعديل بيانات المعدة": "Edit info",
    "أضافة معدة جديدة": "add new equipment",
    "تعديل البيانات": "Edit info",
    "أضافة معدة": "add new equipment",
    "النسخ الأحتياطي": "Backup your data",
    "النسخ الأحتياطي لقواعد البيانات": "Backup your data",
    "أنواع المعدات الرئيسية": "Categories",
    "أنواع المعدات": "Categories",
    "لا يوجد": "No",
    "كل المعدات": "All equipment",
    "كل المواقع": "All locations",
    "اضف الموقع": "Add the location",
    "موقع جديد": "new location",
    "المعدات": "equipment",
    "نوع الالة": "category",
    "الفئة": "code",
    "تسجيلات": "records",
    "فحص المعدة": "scan equipment",
    "المستخدمين": "Users",
    "بحث": "search",
    "الوظيفة": "role",
    "كل الوظائف": "all roles",
    "الأيميل": "Email",
    "التليفون": "phone",
    "تأكيد الأنضمام": "confirm user",
    "معلومات المعدة": "equipment info",
    "من": "from",
    "الى": "to",
    "تعديل المتابعة": "Edit follow up",
    "تقرير المتابعة": "Follow up",
    "التاريخ": "Date",
    "فحص الة جديدة": "Scan new equip",
    "سجل المتابعة للمعدة شهر": "follow up for",
    "سجل المتابعة للمعدة": "follow up report",
    "تقارير": "reports",
    "الموظف": "employee",
    "بعد": "yet",
    "لا توجد نتائج": "No results",
    "ابحث عن موقع": "search for location",
    "النتائج": "results",
    "أكواد": "codes",
    "تسجيل الدخول": "signin",
    "تسجيل الخروج": "logout",
    "عدد تسجيلات نوع المعدة": "category records",
    "عدد تسجيلات الموقع": "Location records",
    "أكواد المعدات": "equips codes",
    "حالات المعدات": "equips status",
    "أضافة موقع": "Add lcoation",
    "عدد تسجيلات المعدة بهذا الكود": "Records of this equip code",
    "عدد تسجيلات المعدات بهده الحالة": "Records of this equip status",
    "عدد التسجيلات في هذا التاريخ": "Records on this date",
    "قم بتوجيه الكاميرا وفحص الكود والانتقال لتفاصيل المعدة":
        "direct the camera to Qrcode and scan it to check the equipment",
    "رفع المتابعة": "submit record"

}


export function translate(val) {
    // val = val.toString();
    return words[val]??val;
    // const match = words[val];
    // if(match) return match;
    // console.log(val)
    // const match2 = val.split(" ").map(word => {
    //     return words[word];
    // }).join(" ");
    // if(match2.length > 2) return match2;
    // return val;
}