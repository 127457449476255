export function filterItems(items, filters) {
    if(items.length == 0) return [];
    return items.filter((item) => {
        let ok = true;
        for(let key in filters) {
            if(key == "from") {
                if(filters['from'] != "") {
                    if(!(new Date(item.date).getTime() >= new Date(filters['from']).getTime())) { 
                        ok = false; break;
                    }
                }
            }
            if(key == "to") {
                if(filters['to'] != "") {
                    if(!(new Date(item.date).getTime() <= new Date(filters['to']).getTime())) { 
                        ok = false; break;
                    }
                }
            }
            //////////////////////////////////////////////////
            if(key == "query") {
                if(!filters['query'] || filters['query'] == "") continue;
                let found = false;
                for(let val in item) {
                    if(item[val].toString().toUpperCase().includes(filters['query'].toUpperCase())) {found = true; break;}
                }
                if(!found) {ok = false; break;}
            }
            //////////////////////////////////////////////////
            if(key != "from" && key != "to" && key != "query") {
                if(filters[key] == "all" || !filters[key] ||  filters[key] == "") continue;
                else if(filters[key].includes("other")) {
                    if(item[key] == filters[key].toString().split("/")[1]) {
                        ok = false;
                        break;
                    }
                }
                else if(item[key] != filters[key]) {
                    ok = false;
                    break;
                }  
            }
        }

        return ok;
      });
}

export function isActive(obj) {
    let active = true;
    for(let key in obj) {
        if(!obj[key] || obj[key] == '') {
            active = false;
            break;
        }
    }
    return active;
}

export function round(val) {
    return Math.round(val);
}

///////////////////// TARGET TRANSFORMATIONS //////////////////////


export function generateRandomString (len) {
    const allCapsAlpha = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"]; 
    const allLowerAlpha = [..."abcdefghijklmnopqrstuvwxyz"]; 
    // const allUniqueChars = [..."~!@#$%^&*()_+-=[]\{}"];
    const allNumbers = [..."0123456789"];
  
    const base = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha];
  
    return [...Array(len)]
    .map(() => base[Math.random()*base.length|0])
    .join('');
}