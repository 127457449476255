var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"toggle-menu",on:{"click":_vm.toggleMenu}},[_c('img',{attrs:{"src":require("../assets/imgs/arrow.png"),"alt":""}})]),_c('div',{staticClass:"menu pt-5"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"items"},[_vm._l((_vm.items.filter(function (item) { return item; })),function(item,i){return _c('div',{key:i,staticClass:"item center",on:{"click":function () {
                _vm.toggleMenu();
                if (item.link == 'logout') { _vm.$store.dispatch('logout'); }
              }}},[_c('i',{class:'fa fa-' + item.icon}),(item.link != 'logout')?_c('router-link',{attrs:{"to":item.link}},[_vm._v(_vm._s(_vm._f("translate")(item.name)))]):_c('a',[_vm._v(_vm._s(_vm._f("translate")(item.name)))])],1)}),_c('div',{staticClass:"item center"},[_c('a',{on:{"click":function () {
                  _vm.toggleMenu();
                  _vm.$store.dispatch('toggleLang');
                }}},[_vm._v(" "+_vm._s(_vm.lang == "english" ? "Arabic" : "English")+" ")]),_c('i',{staticClass:"fa fa-book"})]),(_vm.$store.getters.auth.token)?_c('div',{staticClass:"item center",on:{"click":function () {
                _vm.toggleMenu();
                _vm.$store.dispatch('logout');
              }}},[_c('i',{class:'fa fa-open'}),_c('a',[_vm._v("Logout")])]):_vm._e()],2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 header"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('i',{staticClass:"fa fa-cogs mx-3"}),_c('h4',[_vm._v("Admin Panel")])])])}]

export { render, staticRenderFns }