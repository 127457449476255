import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);
const routes = [
    ///////////////////////////////  ALL  ////////////////////////////////////////////
    {path: '/', component: () => import("./views/scan-device.vue"), beforeEnter: authGuard},
    {path: '/auth/:mode', component: () => import("./views/auth/auth.vue"), beforeEnter: (to, from, next) => {
        if(localStorage.getItem("token")) return next("/");
        next();
    }},
    {path: '/update-by-user/:id', component: () => import("./views/auth/update.vue")},
    { path: "*", component: () => import("./views/not-found.vue") },
    {path: '/edit-record/:id', component: () => import("./views/edit-record.vue"), beforeEnter: authGuard},
    
    
    ////////////////////////////////////////////////////////////////////////////////
    {path: '/followup/:id', component: () => import("./views/followup.vue"), beforeEnter: authGuard},
    
    
    
    ////////////////////////////////  CLIENT AND ADMIN  /////////////////////////////////////////
    {path: '/results', component: () => import("./views/results.vue"), beforeEnter: (to, from, next) => {
        if(!localStorage.getItem('token')) return next("/auth/signin");
        const role = localStorage.getItem('role');
        if(role != 1 && role != 2) return next("/auth/signin");
        next();
    }},
    
    
    //////////////////////////////  ADMIN  /////////////////////////////////////////////
    {path: '/devices', component: () => import("./views/devices.vue"), beforeEnter: adminGuard},
    {path: '/add-device', component: () => import("./views/add-edit-device.vue"), beforeEnter: adminGuard},
    {path: '/edit-device/:id', component: () => import("./views/add-edit-device.vue"), beforeEnter: adminGuard},
    {path: '/users', component: () => import("./views/users.vue"), beforeEnter: adminGuard},
    {path: '/update-by-admin/:id', component: () => import("./views/auth/update.vue"), beforeEnter: adminGuard},
    {path: '/locations', component: () => import("./views/locations.vue"), beforeEnter: adminGuard},
    {path: '/categories', component: () => import("./views/categories.vue"), beforeEnter: adminGuard},
    {path: '/add-category', component: () => import("./views/add-edit-category.vue"), beforeEnter: adminGuard},
    {path: '/edit-category/:id', component: () => import("./views/add-edit-category.vue"), beforeEnter: adminGuard},
    {path: '/backup-restore', component: () => import("./views/backup-restore.vue"), beforeEnter: adminGuard},
    
    
];

function adminGuard(to, from, next) {
    if(!localStorage.getItem('token')) return next("/auth/signin");
        const role = localStorage.getItem('role');
        if(role != 2) return next(`/auth/signin`);
        next();
}

function authGuard(to, from, next) {
    if(!localStorage.getItem('token')) return next("/auth/signin");
    next();
}
 
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;