import axios from 'axios';
import { generateRandomString } from '../helper-functions';

  const state = {
    categories: [],
    locations: [],
    locationDevices: [],
    locationRecords: [],
  };

  const mutations = {};

  const actions = {

    async getSystemData({dispatch}) {
      await dispatch("getCategories");
      await dispatch("getDevicesByLocation");
      await dispatch("getMonthRecordsByLocation");
    },
    /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////
    async getLocations({rootState}) {
      try {
        if(!rootState.generalStore.online) return state.locations = restoreData("locations");
        const res = await axios.get("/getLocations");
        state.locations = res.data.locations;
        await storeData("locations", res.data.locations);
      }
      catch(err) {
        console.log(err);
        // state.locations = await restoreData("locations");
      }
    },
    /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////
    async getCategories({rootState}) {
      try {
        if(!rootState.generalStore.online) return state.categories = await restoreData("categories");
        const res = await axios.get("/getCategories");
        state.categories = res.data.categories;
        await storeData("categories", res.data.categories);
      }
      catch(err) {
        console.log(err);
        // state.categories = await restoreData("categories");
      }
    },
    /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////
    async getDevicesByLocation({rootState}) {
      try {
        if(!rootState.generalStore.online) return state.locationDevices = await restoreData("devices");
        ////////////////////
        if(!rootState.generalStore.auth.location) return;
        const res = await axios.post("/getDevicesByUserLocations", {locations: rootState.generalStore.auth.location});
        state.locationDevices = res.data.devices;
        await storeData("devices", res.data.devices);
      }
      catch(err) {
        console.log(err);
        // state.locationDevices = await restoreData("devices");
      }
    },
    /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////
    async getMonthRecordsByLocation({rootState, dispatch}, _month, device) {
      try {
        if(!rootState.generalStore.online) return state.locationRecords = await restoreData("records");
        if(!rootState.generalStore.auth.location) return;
        ////////////////////
        const year = new Date().getFullYear();
        let month, lastMonth;
        if(_month) {
          month = _month;
          lastMonth = _month;
        } else {
          month = +new Date().getMonth() + 1;
          lastMonth = +new Date().getMonth();
        }
        if(month > +new Date().getMonth() + 1 || month < 1 || lastMonth < 1) return;
        month = month > 9 ? month : `0${month}`;
        lastMonth = lastMonth > 9 ? lastMonth : `0${lastMonth}`;
        //////////////////////
        const filter = {from: `${year}-${lastMonth}-01`, to: `${year}-${month}-31`};
        if(device) {
          filter['location'] = [device.location];
          filter['category'] = device.category;
          filter['code'] = device.code;
        } else {
          filter['location'] = rootState.generalStore.auth.location;
        }
        //////////////////////
        dispatch("changeLoading", true);
        const res = await axios
        .post(`/filterFollowupRecords`, filter);
          dispatch("changeLoading", false);
          if(res.data.err) return dispatch("writeMessage", res.data.err);
          res.data.records.forEach(rec => {
            if(!state.locationRecords.find(lr => lr._id == rec._id)) state.locationRecords.push(rec);
          });
          await storeData("records", state.locationRecords);
      }
      catch(err) {
        console.log(err);
        // state.locationRecords = await restoreData("records");
      }
    }, 
    /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////
    
    async addRecord({rootState, dispatch}, record) {
      addOffline();
      storeData("records", state.locationRecords);
      if(rootState.generalStore.online) dispatch("syncRecords");
      ////////////////////////
      ///////////////////////
      function addOffline() {
        record = {...record, action: 'add'};
        const id = record._id? record._id: 'OFFFF' + generateRandomString(19);
        state.locationRecords.push({_id: id , ...record});
      }
      dispatch("writeMessage", "تم رفع التقرير بنجاح");
    },

    /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////
    
    async editRecord({rootState, dispatch}, record) {
      editOffline();
      storeData("records", state.locationRecords);
      if(rootState.generalStore.online) dispatch("syncRecords");
      /////////////////
      function editOffline() {
        if(record._id && record._id.length >= 10 && !record._id.startsWith('OFFFF')) {
          record = {...record, action: 'edit'};
        }
        if(record._id) {
          const index = state.locationRecords.findIndex(rec => rec._id == record._id);
          if(index > -1) {
            state.locationRecords[index] = record;
            dispatch("writeMessage", "تم رفع التقرير بنجاح");
          }
        }
      }
    },

    /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////

    async deleteRecord({rootState, dispatch}, record) {
      deleteOffline();
      storeData("records", state.locationRecords);
      if(rootState.generalStore.online) dispatch("syncRecords");
      ////////////////
      function deleteOffline() {
        const index = state.locationRecords.findIndex(rec => rec._id == record._id);
        if(index < 0) return;
        if(record._id.startsWith('OFFFF')) {
          state.locationRecords.splice(index, 1);
        }
        if(record._id && record._id.length >= 5 && !record._id.startsWith('OFFFF')) {
          record = {...record, action: 'delete'};
          state.locationRecords[index] = record;
          state.locationRecords = [...state.locationRecords];
          dispatch("writeMessage", "تم رفع التقرير بنجاح");
        }
      }
    },

    /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////

    async syncRecords({dispatch}) {
      dispatch("changeLoading", true);
      const records = await restoreData("records");
      const res = await axios.post("/syncRecords", {records});
      if(res.data.err) return dispatch("writeMessage", res.data.err);
      dispatch("writeMessage", "تمت مزامنة التسجيلات");
      if(res.data.failed.length > 0) dispatch("writeMessage", res.data.failed.length + " records didn't saved to database");
      /////////////////////////
      if(res.data.addedRecords.length > 0) {
        state.locationRecords = [...state.locationRecords, ...res.data.addedRecords];
      }
      ///////
      if(res.data.deletedIds.length > 0) {
        for(let id of res.data.deletedIds) {
          const index = state.locationRecords.findIndex(rec => rec._id == id);
          if(index >= 0) state.locationRecords.splice(index, 1);
        }
        state.locationRecords = [...state.locationRecords];
      }
      ///////
      if(res.data.editedRecords.length > 0) {
        for(let record of res.data.editedRecords) {
          const index = state.locationRecords.findIndex(rec => rec._id == record._id);
          if(index >= 0) state.locationRecords[index] = record;
        }
        state.locationRecords = [...state.locationRecords];
      }
      ///////////////////////
      storeData("records", state.locationRecords);
      dispatch("changeLoading", false);
    },

  };


  const getters = {
    categories() {
      return state.categories; 
    },
    locations() {
      return state.locations; 
    },
    locationDevices() {
      return state.locationDevices; 
    },
    locationRecords() {
      return state.locationRecords;
    },
  }



  export default {
    state,
    mutations,
    actions,
    getters
  }

  
  async function storeData(type, data) {
    localStorage.setItem(type, JSON.stringify(data));
  }

  async function restoreData(type) {
    try {
      if(!localStorage.getItem(type)) return [];
      const data = JSON.parse(localStorage.getItem(type));
      return data;
    }
    catch(err) {
      console.log(err);
    }
  }