<template>
  <div>
    <div v-if="!ready" class="loading">
      <loading />
    </div>
    <side-bar />
    <app-message />
    <div v-if="ready">
      <audio style="display: none" id="beep1" ref="beep1" controls>
        <source
          :src="'https://www.soundjay.com/misc/censor-beep-01.mp3'"
          type="audio/mp3"
        />
      </audio>
      <app-header />

      <div class="page" :class="{ english: $store.getters.lang == 'english' }">
        <router-view :key="routerKey"></router-view>
      </div>
      <!-- <app-footer /> -->
    </div>
    <div v-if="loading" class="_loading">
      <div class="animate-loading" id="loading-bar"></div>
    </div>
  </div>
</template>

<script>
import Header from "./components/header";
// import Footer from "./components/footer";
import AppMessage from "./components/message";
import SideBar from "./components/sidebar.vue";
import { generateRandomString } from "./helper-functions";
import { App as CapacitorApp } from "@capacitor/app"
export default {
  name: "App",
  created() {
    CapacitorApp.addListener("backButton", ({canGoBack}) => {
      if(!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        this.$router.back();
      }
    });
  },
  destroyed() {
    this.$store.dispatch("stopInterval");
  },
  data() {
    return {
      routerKey: "",
    };
  },
  components: {
    appHeader: Header,
    // appFooter: Footer,
    AppMessage,
    SideBar,
  },
  computed: {
    ready() {
      return this.$store.getters.ready;
    },
    loading() {
      return this.$store.getters.loading;
    },
    online() {
      return this.$store.getters.online;
    },
  },
  watch: {
    $route() {
      this.routerKey = generateRandomString(8);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "Cairo";
}
.page {
  padding-top: 70px;
  padding-bottom: 70px;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.599);
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  color: #fff;
}

._loading {
  position: fixed; 
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 10px; 
  background: #fff;
  z-index: 500;
  #loading-bar {
    height: 100%;
    background: green;
  }
}

@keyframes animate-loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.animate-loading {
  animation: animate-loading 2s linear infinite;
}
</style>
