<template>
  <div>
    <div v-if="id" class="center" ref="qrcode">
      <div>
        <vue-qrcode :text="id"></vue-qrcode>
        <div class="center download">
          <a @click="downloadQrCode">Download</a>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode-component";
export default {
  components: { VueQrcode },
  props: ["id", "name"],
  data() {
    return {};
  },
  methods: {
    downloadQrCode() {
      const qr = this.$refs.qrcode;
      //   const qr = document.getElementById("qrcode");
      const canvas = qr.getElementsByTagName("canvas")[0];
      const url = canvas.toDataURL("image/png");
      /////////////
      // const blob = new Blob([image])
      // const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.download = this.name;
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  background: rgba(255, 255, 255, 0.711);
  padding: 2px;
  width: 100%;
  a {
    text-align: center;
    color: rgb(13, 140, 108);
  }
}
</style>
