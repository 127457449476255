<template>
  <nav class="navbar navbar-dark bg-dark navbar-expand-lg fixed-top">
    <div class="container-fluid d-flex justify-content-between">
      <div class="center">
        <img src="../assets/imgs/logo.png" alt="logo" class="img-fluid" />
        <a class="navbar-brand">Pluto For Pest Control</a>
        <!-- <button
          id="nav-btn"
          type="button"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsiveExtraLarge"
          aria-controls="navbarResponsiveExtraLarge"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button> -->
      </div>
      <div class="collapse navbar-collapse" id="navbarResponsiveExtraLarge" dir="rtl">
        <ul class="navbar-nav" :dir="$store.getters.lang == 'english' ? 'ltr' : 'rtl'">
          <div v-for="(item, i) in listItems" :key="i">
            <li v-if="item" class="nav-item">
              <a
                @click="() => $router.push(item.link)"
                class="nav-link active"
                aria-current="page"
                data-toggle="collapse"
                >{{ item.name | translate }}</a
              >
            </li>
          </div>
          <!------------------->
          <li class="nav-item">
            <a
              @click="$store.dispatch('toggleLang')"
              class="nav-link active"
              aria-current="page"
              data-toggle="collapse"
              >{{ lang == "english" ? "Arabic" : "English" }}</a
            >
          </li>
          <li>
            <a v-if="token" @click="$store.dispatch('logout')" class="nav-link active"
              >Logout</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  created() {},
  updated() {
    setTimeout(() => {
      const navLinks = document.querySelectorAll(".nav-item");
      const toggler = document.getElementById("nav-btn");
      navLinks.forEach((item) => {
        item.addEventListener("click", () => {
          toggler.click();
        });
      });
    }, 1000);
  },
  computed: {
    token() {
      return this.$store.getters.auth.token;
    },
    lang() {
      return this.$store.getters.lang;
    },
    listItems() {
      return this.$store.getters.listItems;
    },
  },
  watch: {
    listItems(val) {
      console.log(val);
    },
  },
};
</script>
<style scoped lang="scss">
nav {
}
a {
  cursor: pointer;
  font-size: 0.8rem;
}
@media (max-width: 700px) {
  #nav-btn {
    display: none;
  }
}
img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
</style>
