import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store/index.js'
import axios from 'axios'
import Loading from "./components/loading.vue"
import ServerTable from "./components/server-responsive-table.vue"
import ResponsiveTable from "./components/responsive-table.vue"
import AppTable from "./components/table.vue"
import Qrcode from "./components/qrCode.vue"
import vuetify from './plugins/vuetify'
import LineChart from "./components/chart.js"
import JsonExcel from "vue-json-excel";
import './assets/styles.scss'
import { translate } from './tanslation-en'
import { Capacitor } from '@capacitor/core'

// axios.defaults.baseURL = process.env.NODE_ENV == "production"? window.location.origin: "https://pluto-pest-control.herokuapp.com/";
axios.defaults.baseURL = process.env.NODE_ENV == "production"? window.location.origin: "http://localhost:5000/";
// if(Capacitor.getPlatform() == 'android') axios.defaults.baseURL = "https://pluto-pest-control.acadomen.com/"
if(Capacitor.getPlatform() == 'android') axios.defaults.baseURL = "https://system.plutoegypt.com/"
Vue.config.productionTip = false


Vue.component("downloadExcel", JsonExcel);
Vue.component("loading", Loading);
Vue.component("qr-code", Qrcode);
Vue.component("server-table", ServerTable);
Vue.component("responsive-table", ResponsiveTable);
Vue.component("app-table", AppTable);
Vue.component("line-chart", LineChart);

Vue.filter("translate", val => {
  if(store.getters.lang == "english") return translate(val);
  return val;
})

Vue.filter("month", val => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return months[val];
})

new Vue({
  created() {
    store.dispatch("initApp");
  },
  router,
  store,
  vuetify,
  render: h => {
    const page = h(App);
    return page;
  }
}).$mount('#app');

export const storee = store;