<template>
  <section>
    <!------------------------------------------------->
    <div v-if="items.length == 0 && !loading" class="row justify-content-center">
      <div class="col-md-12">
        <h3 class="text-center">
          {{ "لا يوجد" | translate }} {{ title || "items" | translate }}
          {{ "بعد" | translate }}
        </h3>
      </div>
    </div>
    <!----------------->
    <div v-if="items.length > 0" class="responsive-table">
      <!-- <div v-if="title" class="row">
        <div class="col-md-12">
          <h3 class="title">{{ title }}</h3>
        </div>
      </div> -->
      <div v-if="loading" class="center">
        <loading />
      </div>
      <div class="row px-md-2 items">
        <div class="col-md-12">
          <div ref="item" v-for="(item, i) in oItems" :key="i" class="item">
            <div class="table-row">
              <div v-for="(col, c) in colsNames" :key="c" class="">
                <div class="name _col">
                  <span>
                    {{ item.elmentName | translate }}
                    {{
                      col == "elementName"
                        ? item.elmentName == "0"
                          ? ""
                          : item.elementName
                        : col | translate
                    }}:
                  </span>
                  <h5 v-if="colsTypes[c] == 'readonly'">
                    {{ item[colsTitles[c]] | translate }}
                  </h5>
                  <input
                    v-if="colsTypes[c] == 'input'"
                    type="text"
                    :value="round(item[colsTitles[c]])"
                    @change="
                      (e) =>
                        $emit('tableInput', {
                          index: i,
                          key: colsTitles[c],
                          val: e.target.value,
                        })
                    "
                  />
                </div>
              </div>
              <div v-if="btns && btns.length > 0" class="">
                <div class="table-btns">
                  <div v-for="(btn, b) in btns" :key="b" class="table-btn">
                    <i @click="btn.method(item, items)" :class="'fa fa-' + btn.icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-footer">
        <div class="d-flex flex-wrap justify-content-center align-items-center">
          <div>
            <span>{{ from }}</span>
            <span>-</span>
            <span>{{ to }}</span>
            <span>of</span>
            <span>{{ items.length }}</span>
          </div>
          <div>
            <span>
              <i @click="prev" class="fa fa-arrow-left"></i>
            </span>
            <span>
              <i @click="next" class="fa fa-arrow-right"></i>
            </span>
          </div>
          <div>
            <select @change="calcRows" v-model.number="rowsPerStep" class="form-control">
              <option value="10">10</option>
              <option value="30">31</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="1000">all</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    "title",
    "items",
    "loading",
    "colsTitles",
    "colsNames",
    "colsTypes",
    "btns",
    "color",
  ],
  created() {
    this.calcRows();
  },
  updated() {
    const items = this.$refs.item || [];
    if (items.length == 0) return;
    items.forEach((item, i) => {
      if (i % 2 == 0) {
        item.style.background = this.color;
      }
    });
  },
  data() {
    return {
      oItems: [],
      stepNumber: 0,
      rowsPerStep: 31,
      totalSteps: 0,
      ////////////////
      from: 0,
      to: 0,
    };
  },
  watch: {
    items() {
      this.calcRows();
    },
    stepNumber(val) {
      const from = (val - 1) * this.rowsPerStep;
      const to = val * this.rowsPerStep;
      this.oItems = [...this.items].slice(from, to);
      this.from = from + 1;
      this.to = from + this.oItems.length;
    },
  },
  methods: {
    next() {
      if (this.stepNumber == this.totalSteps) return;
      this.stepNumber++;
    },
    prev() {
      if (this.stepNumber == 1) return;
      this.stepNumber--;
    },
    calcRows() {
      this.totalSteps = Math.ceil(this.items.length / this.rowsPerStep);
      if (this.items.length > 0) {
        // const number = this.stepNumber;
        // this.stepNumber = number == 0 ? 1 : number;
        this.stepNumber = 1;
        ////////////////////////////////
        const from = (this.stepNumber - 1) * this.rowsPerStep;
        const to = this.stepNumber * this.rowsPerStep;
        this.oItems = [...this.items].slice(from, to);
        this.from = from + 1;
        this.to = from + this.oItems.length;
      } else {
        this.oItems = [];
      }
    },
    round(val) {
      return Math.round(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
}
.items {
  height: 400px;
  //   max-height: 500px;
  overflow-y: auto;
  .item {
    text-align: left;
    font-weight: 600;
    padding: 5px;
    margin-top: 13px;
    background: rgb(249, 249, 250);
    color: rgba(20, 20, 20, 0.776);
    span {
      color: rgba(26, 25, 25, 0.714);
      font-weight: 700;
      margin-right: 6px;
      //   text-decoration: underline;
    }
    h5 {
      font-size: 1rem;
      @media (max-width: 700px) {
        font-size: 0.8rem;
      }
    }
    &:nth-child(odd) {
      // background: rgb(13, 133, 13);
      color: #fff;
      span {
        color: rgba(255, 255, 255, 0.728);
      }
      ._col {
        @media (max-width: 700px) {
          &.name {
            border-bottom: 2px solid rgba(241, 239, 239, 0.619);
          }
        }
      }
    }
    ._col {
      width: 110px;
      h5 {
        overflow-x: auto;
      }
      @media (max-width: 700px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &.name {
          margin: 3px 0;
          border-bottom: 2px solid rgba(48, 47, 47, 0.619);
        }
      }
    }
  }
}

.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.table-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .table-btn {
    cursor: pointer;
    padding: 10px;
  }
}

.table-footer {
  background: rgba(223, 221, 221, 0.551);
  padding: 5px;
  span {
    margin: 5px;
    i {
      font-size: 1.4rem;
      cursor: pointer;
    }
  }
}
input {
  width: 40px;
}

::-webkit-scrollbar {
  display: none;
}
</style>
